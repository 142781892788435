<template>
  <form
    @submit.prevent="submit"
    enctype="multipart/form-data"
  >
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md8">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-checkbox
          :id="'active-input'"
          :label="$t('themes.inputs.activeInput')"
          v-model="theme.active"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <h5>{{ $t('themes.inputs.theme_config') }}</h5>
      </div>
      <div class="flex xs12">
        <div class="row">
          <div class="flex xs6">
            <h6>{{ $t('themes.inputs.background_type') }}</h6>
          </div>
          <div class='flex xs3'>
            <va-radio-button
              option="image"
              v-model="config.background_type"
              :label="$t('layout.image')"
            />
          </div>
          <div class="flex xs3">
            <va-radio-button
              option="color"
              v-model="config.background_type"
              :label="$t('layout.color')"
            />
          </div>
        </div>
      </div>
      <div class="flex xs12">
        <div class="row">
          <div class="flex xs6">
            <h6>{{ $t('themes.inputs.login_type') }}</h6>
          </div>
          <div class='flex xs3'>
            <va-radio-button
              option="image"
              v-model="config.login_type"
              :label="$t('layout.image')"
            />
          </div>
          <div class="flex xs3">
            <va-radio-button
              option="color"
              v-model="config.login_type"
              :label="$t('layout.color')"
            />
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="flex xs12">
        <h5>{{ $t('themes.inputs.theme_colors') }}</h5>
      </div>
      <div class="flex xs12 md6">
        <span
          class="title no-wrap"
          :style="{color: this.$themes.primary}"
        >
          {{ $t('themes.inputs.primary_tint') }}
        </span>
        <va-palette-custom
          :palette="palette"
          v-model="theme.primary_color"
        />
      </div>
      <div class="flex xs12 md6">
        <span
          class="title no-wrap"
          :style="{color: this.$themes.success}"
        >
          {{ $t('themes.inputs.green_tint') }}
        </span>
        <va-palette-custom
          :palette="palette"
          v-model="theme.success_color"
        />
      </div>
      <div class="flex xs12 md6">
        <span
          class="title no-wrap"
          :style="{color: this.$themes.danger}"
        >
          {{ $t('themes.inputs.red_tint') }}
        </span>
        <va-palette-custom
          :palette="palette"
          v-model="theme.danger_color"
        />
      </div>
      <div class="flex xs12 md6">
        <span
          class="title no-wrap"
          :style="{color: this.$themes.info}"
        >
          {{ $t('themes.inputs.blue_tint') }}
        </span>
        <va-palette-custom
          :palette="palette"
          v-model="theme.info_color"
        />
      </div>
      <div class="flex xs12">
        <span
          class="title no-wrap"
          :style="{color: this.$themes.info}"
        >
          {{ $t('themes.inputs.background_color') }}
        </span>
        <va-palette-custom
          :palette="palette"
          v-model="theme.background_color"
        />
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="flex xs12">
        <h5>{{ $t('themes.inputs.theme_images') }}</h5>
      </div>
      <div class="flex xs12 md4">
        <h6>{{ $t('themes.inputs.navbarImageInput') }}</h6>
        <picture-input
          ref="navbarImageInput"
          width="600"
          height="600"
          margin="16"
          size="10"
          :style="{'background-color': theme.primary_color}"
          :z-index="1"
          :crop="false"
          :prefill="prefill.navbar_image"
          button-class="va-button va-button--flat color--primary"
          :custom-strings="imageTranslations"
          @change="onChange('navbar_image', 'navbarImageInput')"
        >
        </picture-input>
      </div>
      <div class="flex xs12 md4">
        <h6>{{ $t('themes.inputs.loginImageInput') }}</h6>
        <picture-input
          ref="loginImageInput"
          width="600"
          height="600"
          margin="16"
          size="10"
          :z-index="1"
          :crop="false"
          :prefill="prefill.login_image"
          button-class="va-button va-button--flat color--primary"
          :custom-strings="imageTranslations"
          @change="onChange('login_image', 'loginImageInput')"
        >
        </picture-input>
      </div>
      <div class="flex xs12 md4">
        <h6>{{ $t('themes.inputs.backgroundImageInput') }}</h6>
        <picture-input
          ref="backgroundImageInput"
          width="600"
          height="600"
          margin="16"
          size="10"
          :z-index="1"
          :crop="false"
          :prefill="prefill.background_image"
          button-class="va-button va-button--flat color--primary"
          :custom-strings="imageTranslations"
          @change="onChange('background_image', 'backgroundImageInput')"
        >
        </picture-input>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMixin } from '@/services/validator'
import { mapGetters } from 'vuex'
const PictureInput = () => import(/* webpackPrefetch: true */ 'vue-picture-input')

export default {
  name: 'themes-form',
  components: {
    PictureInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    theme: {
      type: Object,
      required: false,
      default: function () { return { active: false } },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      prefill: {
        navbar_image: null,
        background_image: null,
        login_image: null,
      },
      images: {
        navbar_image: null,
        background_image: null,
        login_image: null,
      },
      imageTranslations: {
        upload: this.$t('layout.images.upload_error'),
        drag: this.$t('layout.images.drag'),
        tap: this.$t('layout.images.tap'),
        change: this.$t('layout.images.change'),
        remove: this.$t('layout.images.remove'),
        select: this.$t('layout.images.select'),
        selected: this.$t('layout.images.selected'),
        fileSize: this.$t('layout.images.file_size', { size: 10 }),
        fileType: this.$t('layout.images.file_type'),
        aspect: this.$t('layout.images.aspect'),
      },
      config: {
        background_type: 'image',
        login_type: 'image',
      },
      palette: Object.values(this.$themes),
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  watch: {
    theme (val) {
      this.updateThemeData(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    if (this.theme) {
      this.updateThemeData(this.theme)
    }
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    setPrefillImage (prefill, val) {
      if (!val[prefill]) return

      let base = ''
      if (process.env.NODE_ENV === 'development') {
        base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
      }
      this.prefill[prefill] = base + val[prefill]
    },
    onChange (source, ref) {
      if (!this.$refs[ref].file) {
        return console.error('FileReader API not supported: use the <form>, Luke!')
      }
      this.images[source] = this.$refs[ref].file
    },
    async updateThemeData (theme) {
      this.setFormData(theme)
      this.setPrefillImage('navbar_image', theme)
      this.setPrefillImage('login_image', theme)
      this.setPrefillImage('background_image', theme)

      if (theme.id) {
        await this.$nextTick()
        this.validateAll()
        this.config.background_type = this.theme.background_type || 'image'
        this.config.login_type = this.theme.login_type || 'image'
      }
    },
    async submit () {
      await this.validateAll()
      if (!this.formReady) return

      const theme = new FormData()
      if (this.theme.id) {
        theme.append('id', this.theme.id)
      }
      theme.append('name', this.getFormValue('name'))
      theme.append('primary_color', this.theme.primary_color)
      theme.append('success_color', this.theme.success_color)
      theme.append('danger_color', this.theme.danger_color)
      theme.append('info_color', this.theme.info_color)
      theme.append('background_color', this.theme.background_color)
      theme.append('background_type', this.config.background_type)
      theme.append('login_type', this.config.login_type)
      theme.append('active', this.theme.active ? 1 : 0)
      if (this.images.navbar_image) {
        theme.append('navbar_image', this.images.navbar_image)
      }
      if (this.images.login_image) {
        theme.append('login_image', this.images.login_image)
      }
      if (this.images.background_image) {
        theme.append('background_image', this.images.background_image)
      }
      this.$emit('submit', theme)
    },
  },
}
</script>
